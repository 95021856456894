import { UIMatch, useMatches, useNavigate } from "@remix-run/react";
import { hasToken, request } from "./request";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Guidance, UserProfile } from "./types";
import { useCallback, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import mixpanel, { Dict } from "mixpanel-browser";
import { useTranslate } from "@tolgee/react";
import { GenericOAuth2 } from "@capacitor-community/generic-oauth2";
import { getNavigateLink, trackEvent } from "./utils";

export function useRegistrationAccountType(): "mentor" | "mentee" {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.localStorage.getItem("user_type")) {
      navigate("/onboarding/type");
    }
  }, [navigate]);

  return window.localStorage.getItem("user_type") as "mentor" | "mentee";
}

export function useUser() {
  return useQuery({
    queryKey: ["user"],
    staleTime: 5000,
    queryFn: async () =>
      hasToken()
        ? request
            .get("profile")
            .json<{ data: UserProfile }>()
            .then((x) => x.data)
        : undefined,
  });
}

export function useGuidances() {
  return useQuery({
    queryKey: ["guidances"],
    queryFn: () => request.get("guidances").json<Record<string, Guidance[]>>(),
  });
}

export function useCheckNotifications() {
  const navigate = useNavigate();

  const checkNotifications = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    if (!hasToken()) {
      return;
    }

    if (window.localStorage.getItem("notifications_skipped") === "true") {
      return;
    }

    const { receive } = await FirebaseMessaging.checkPermissions();

    if (receive == "prompt") {
      navigate("/notifications");
    }
  }, [navigate]);

  useEffect(() => {
    checkNotifications();
  }, [checkNotifications]);
}

export function hasExpressedConsent() {
  return mixpanel.has_opted_in_tracking() || mixpanel.has_opted_out_tracking();
}

export function useTrack(name: string, properties?: Dict) {
  useEffect(() => {
    trackEvent(name, properties);
  }, [name, properties]);
}

export function useRouteBodyClasses() {
  const [classes, setClasses] = useState<string[]>([]);
  const matches = useMatches() as UIMatch<unknown, { bodyClass: string }>[];

  // Only set in the browser
  useEffect(() => {
    setClasses(
      matches
        .filter((match) => match.handle?.bodyClass)
        .map((match) => match.handle?.bodyClass),
    );
  }, [matches]);

  return classes;
}

export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const isDesktop = window.matchMedia("(min-width: 640px)");
    setIsDesktop(isDesktop.matches);
    const handleChange = (e: MediaQueryListEvent) => setIsDesktop(e.matches);
    isDesktop.addEventListener("change", handleChange);
    return () => {
      isDesktop.removeEventListener("change", handleChange);
    };
  }, []);

  return isDesktop;
}

export function useSkills() {
  return useQuery({
    queryKey: ["skills"],
    staleTime: 0,
    gcTime: 0,
    queryFn: () => request.get("onboarding/skills").json<Array<string>>(),
  });
}

export function useMentorTypes() {
  const { t } = useTranslate();

  return [
    {
      value: "university",
      label: t("hooks.useMentorTypes.university.label", "University Mentor"),
      description: t(
        "hooks.useMentorTypes.university.description",
        "I work for a university/college and offer mentoring as part of my business activities, for example as a student advisor.",
      ),
    },
    {
      value: "professional",
      label: t(
        "hooks.useMentorTypes.professional.label",
        "Professional Mentor",
      ),
      description: t(
        "hooks.useMentorTypes.professional.description",
        "I am self-employed and would like to offer mentoring in the form of services for sale.",
      ),
    },
    {
      value: "non_profit",
      label: t("hooks.useMentorTypes.non_profit.label", "Non-Profit Mentor"),
      description: t(
        "hooks.useMentorTypes.non_profit.description",
        "I am an independent mentor and would like to offer mentoring on a non-profit basis",
      ),
    },
    {
      value: "corporate",
      label: t("hooks.useMentorTypes.corporate.label", "Corporate Mentor"),
      description: t(
        "hooks.useMentorTypes.corporate.description",
        "I work for a company and offer mentoring as part of my corporate activities, for example as a recruiter",
      ),
    },
  ];
}

export function useEmploymentStatuses() {
  const { t } = useTranslate();

  return [
    {
      value: "employed",
      label: t("hooks.useEmploymentStatuses.employed.label", "Employed"),
    },
    {
      value: "self_employed",
      label: t(
        "hooks.useEmploymentStatuses.self_employed.label",
        "Self-employed",
      ),
    },
    {
      value: "student",
      label: t("hooks.useEmploymentStatuses.student.label", "Student"),
    },
    {
      value: "high_school_graduate",
      label: t(
        "hooks.useEmploymentStatuses.high_school_graduate.label",
        "High School Graduate",
      ),
    },
    {
      value: "unemployed",
      label: t("hooks.useEmploymentStatuses.unemployed.label", "Unemployed"),
    },
    {
      value: "retired",
      label: t("hooks.useEmploymentStatuses.retired.label", "Retired"),
    },
    {
      value: "other",
      label: t("hooks.useEmploymentStatuses.other.label", "Other"),
    },
  ];
}

export function useSocialAuth(type: "mentor" | "mentee") {
  const [socialProviderLoading, setProviderLoading] = useState<
    "google" | "apple" | "linkedin" | null
  >(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: {
      provider: string;
      access_token: string;
      type: "mentor" | "mentee";
      first_name?: string;
      last_name?: string;
    }) =>
      request
        .post("social-auth", { json: data })
        .json<{ token: string; user: UserProfile }>(),
    onSuccess: ({ token, user }) => {
      setProviderLoading(null);
      localStorage.setItem("token", token);
      queryClient.setQueryData(["user"], user);
      const destination = getNavigateLink(user.type, user.onboarding_status);

      if (!hasExpressedConsent()) {
        return navigate("/consent", { state: { destination } });
      }
      navigate(destination);
    },
    onError: (error) => {
      setProviderLoading(null);
      console.error("Social login error", error);
    },
  });

  const googleLogin = () => {
    setProviderLoading("google");
    GenericOAuth2.authenticate({
      authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
      accessTokenEndpoint: "https://www.googleapis.com/oauth2/v4/token",
      scope: "email profile",
      web: {
        appId:
          "100762128916-30tjfvo58jljasffmsv4qgvtj0lk4t4o.apps.googleusercontent.com",
        responseType: "token",
        accessTokenEndpoint: "",
        redirectUrl: location.origin,
        windowOptions: "height=600,left=0,top=0",
      },
      ios: {
        appId:
          "100762128916-al3gf8imbe72lmjtim1139uhp0h4bmkp.apps.googleusercontent.com",
        responseType: "code",
        redirectUrl: "app.mentory.mentory:/",
      },
      android: {
        appId:
          "100762128916-4kba33a0a1m5ad9d3ble79hlbkmak8ct.apps.googleusercontent.com",
        responseType: "code",
        redirectUrl: "app.mentory.mentory:/",
      },
    })
      .then((response) => {
        mutation.mutate({
          provider: "google",
          access_token:
            Capacitor.getPlatform() === "web"
              ? response.access_token
              : response.access_token_response.access_token,
          type,
        });
      })
      .catch((reason) => {
        setProviderLoading(null);
        console.error("Google OAuth rejected", reason);
      });
  };

  const appleLogin = () => {
    setProviderLoading("apple");
    GenericOAuth2.authenticate({
      appId: "app.mentory.mentory",
      authorizationBaseUrl: "https://appleid.apple.com/auth/authorize",
    })
      .then((response) => {
        mutation.mutate({
          provider: "apple",
          access_token: response.id_token,
          type,
          first_name: response.given_name,
          last_name: response.family_name,
        });
      })
      .catch((reason) => {
        setProviderLoading(null);
        console.error("Apple OAuth rejected", reason);
      });
  };

  const linkedinLogin = () => {
    setProviderLoading("linkedin");
    GenericOAuth2.authenticate({
      authorizationBaseUrl: "https://www.linkedin.com/oauth/v2/authorization",
      scope: "openid email profile",
      appId: "787zupe5ien7ns",
      responseType: "code",
      state: "",
      web: {
        responseType: "code",
        redirectUrl: location.origin,
        windowOptions: "height=600,left=0,top=0",
      },
    })
      .then((response) => {
        console.log("LinkedIn OAuth response", response);
        mutation.mutate({
          provider: "linkedin",
          access_token: response.authorization_response.code,
          type,
        });
      })
      .catch((reason) => {
        setProviderLoading(null);
        console.error("LinkedIn OAuth rejected", reason);
      });
  };

  const socialProviderLogin = (provider: "google" | "apple" | "linkedin") => {
    switch (provider) {
      case "google":
        googleLogin();
        break;
      case "apple":
        appleLogin();
        break;
      case "linkedin":
        linkedinLogin();
        break;
    }
  };

  return {
    socialProviderLoading,
    socialProviderLogin,
  };
}
